.new-course-bg {
    width: 100%;
    background-color: #0000002b;
    position: fixed;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 20;
}

.course-sidebar {
    width: 400px;
    background-color: white;
    padding: 20px;
    height: 90vh;
    overflow-y: hidden;
    margin: auto 10px;
    transition: .5s ease;
    transform: translateX(1000px);
    border-radius: 30px;
}

div.inner-scroll {
    height: 100%;
    overflow-y: scroll;
}

.slow-show {
    transition: .5s ease;
    transform: translateX(0px);
}

/* course page */
.course-image {
    height: 250px;
}
.course-image > *, .course-image {
    transition: .5s ease;
}
.course-image img:hover {
    transform: scale(1.1, 1.1);
}



@media screen and (max-width: 600px) {
    .course-sidebar {
        width: 100%;
    }
}