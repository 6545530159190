.navigators {
    padding: 5px;
    border-radius: 50px;
    background-color: #EFF1F6;
    width: fit-content;
}

.cust-btn {
    border-radius: 360px;
    padding: 10px 50px;
    font-size: 16px;
    cursor: pointer;
}

code {
    overflow-x: hidden !important;
    padding: 20px !important;
    border-radius: 10px;
}